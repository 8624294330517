export const environment = {
  production: true,
  branch: 'master',
  bugsnagApiKey: '121ea2a1a1400f165e54cb2ce7365249',
  api: 'https://dev-api.softbrik.com',
  allo: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  stak: 'https://xgr5ilpg21.execute-api.eu-west-1.amazonaws.com/prod',
  trak: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  file: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  contacts: 'https://977rngf245.execute-api.eu-west-1.amazonaws.com/prod',
  trust: 'https://8r9ajndd4a.execute-api.eu-west-1.amazonaws.com/prod',
  health: 'https://ID.execute-api.eu-west-1.amazonaws.com/prod',
  survey: 'https://2ac4zfakjd.execute-api.eu-west-1.amazonaws.com/prod',
};
